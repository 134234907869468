import React from 'react';

// import AboutMe from '../components/PageFragments/HomePage/AboutMe'; (this is no longer referenced since it's now included in the sidebar)
import Experience from '../components/PageFragments/HomePage/Experience';
import SelectedResearch from '../components/PageFragments/HomePage/SelectedResearch';
import SelectedProjects from '../components/PageFragments/HomePage/SelectedProjects';
import ConferenceProjects from '../components/PageFragments/HomePage/SelectedConferences';
import SEO from '../components/Seo';
import SelectedConferences from '../components/PageFragments/HomePage/SelectedConferences';

export default () => (
  <>
    <SEO
      title="Home"
      description=""
      path=""
      keywords={['NodeJS', 'Gatsby']}
    />
    <SelectedProjects />
    <SelectedResearch />
    <SelectedConferences />
  </>
);
