import { Col, FlexboxGrid } from 'rsuite';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import React from 'react';

import ConferenceCard from '../../ConferenceCard';

import * as styles from './homePage.module.less';

// add PROJECTS PAGE (JUST COPY PUBLICATIONS AND RENAME)

const SelectedConferences = () => {
  const data = useStaticQuery(graphql`
  {
    allTag {
      edges {
        node {
          name
          color
          path
        }
      }
    }
    allMdx(
      filter: { 
        fileAbsolutePath: { regex: "/conferences\/.*\/index\\.mdx?$/" }
        frontmatter: { 
          selected: { eq: true } 
        }
      }
      sort: { fields: [frontmatter___priority, frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 320, maxHeight: 180, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            date
            venue
            authors
            path
            title
            tags
            excerpt
            selected
            priority
            links {
              name
              url
            }
          }
          fileAbsolutePath
        }
      }
    }
  }
`);

  const tags = data.allTag ? data.allTag.edges : [];
  const tagsMap = _.mapValues(_.keyBy(tags, (tag) => tag.node.name), 'node');
  return (data.allMdx && data.allMdx.edges && data.allMdx.edges.length) ? (
    <div className={styles.homepageSection}>
      <h1 className="titleSeparate">Selected Conferences</h1>
      <FlexboxGrid className="spacing-grid">
        {data.allMdx &&
          data.allMdx.edges.map((val, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <FlexboxGrid.Item as={Col} xs={24} sm={24} md={24} lg={24}>
              <ConferenceCard data={val} tagsMap={tagsMap} />
            </FlexboxGrid.Item>
          ))}
      </FlexboxGrid>
    </div>
  ) : (
    <></>
  );
};

export default SelectedConferences;
